<!--版权声明-->
<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="../../assets/common/topTitleBg.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">{{ title }}</div>
        </HeadContent>
        <div class="container">
            <div class="bbbox">
                <div class="content" v-if="title=='质量管理体系认证'">
                    <div class="btit">
                        <div>质量管理体系认证服务[QMS]（ISO9001）</div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">什么是ISO9001质量管理体系</li>
                        <div>
                            ISO9000族标准是国际标准化组织（ISO）于1987年颁布的在全世界范围内通用的关于质量管理和质量保证方面的系列标准。1994年，国际标准化组织对其进行了全面的修改，并重新颁布实施。2000年，ISO对ISO9000系列标准进行了重大改版，2008年及2015年又做了进一步的修改。随着质量管理的理论与实践的发展，许多国家和企业为了保证产品质量，选择和控制供应商，纷纷制定国家或公司标准，对公司内部和供方的质量活动制定质量体系要求，产生了质量保证标准。随着国际贸易的迅速发展，为了适应产品和资本流动的国际化趋势，寻求消除国际贸易中技术壁垒的措施，ISO/TC176组织各国专家在总结各国质量管理经验的基础上，制定了ISO
                            9000系列国际标准。一个组织可依据ISO9000标准建立、实施和改进其质量体系，并可作为机构间（第二方认证）或外部认证机构（第三方认证）的认证依据。该系列标准目前已被90多个国家等同或等效采用，是全世界最通用的国际标准，在全球产生了广泛深刻的影响。
                        </div>

                        <li class="ul-title">公司通过取得ISO9001认证能带来如下的益处：</li>
                        <div>
                            A
                            强调以顾客为中心的理念，明确公司通过各种手段去获取和理解顾客的要求，确定顾客要求，通过体系中各个过程的运作满足顾客要求甚至超越顾客要求，并通过顾客满意的测量来获取顾客满意程序的感受，以不断提高公司在顾客心中的地位，增强顾客的信心；<br>
                            B
                            明确要求公司最高管理层直接参与质量管理体系活动，从公司层面制定质量方针和各层次质量目标，最高管理层通过及时获取质量目标的达成情况以判断质量管理体系运行的绩效，直接参与定期的管理评审掌握整个质量体系的整体状况，并及时对于体系不足之处采取措施，从公司层面保证资源的充分性；<br>
                            C
                            明确各职能和层次人员的职责权限以及相互关系，并从教育、培训、技能和经验等方面明确各类人员的能力要求，以确保他们是胜任的，通过全员参与到整个质量体系的建立、运行和维持活动中，以保证公司各环节的顺利运作；<br>
                            D
                            明确控制可能产生不合格产品的各个环节，对于产生的不合格产品进行隔离、处置，并通过制度化的数据分析，寻找产生不合格产品的根本原因，通过纠正或预防措施防止不合格发生或再次发生，从而不断降低公司发生的不良质量成本，并通过其他持续改进的活动来不断提高质量管理体系的有效性和效率，从而实现公司成本的不断降低和利润的不断增长；<br>
                            E
                            通过单一的第三方注册审核代替累赘的第二方工厂审查，第三方专业的审核可以更深层次地发现公司存在的问题，通过定期的监督审核来督促公司的人员按照公司确定的质量管理体系规范来开展工作；<br>
                            F 获得质量体系认证是取得客户配套资格和进入国际市场的敲门砖，也是企业开展供应链管理很重要的依据。
                        </div>

                        <li class="ul-title">什么是有机？</li>
                        <div>
                            这里的”有机”是指一种产品生产、加工方式，而非”有机化学”。根据我国《有机产品》国家标准（GB/T
                            19630-2011）的规定，有机产品是指生产、加工、销售过程符合该标准的供人类消费、动物食用的产品。我国《有机产品》国家标准规定，有机产品生产过程中不得使用化学合成的农药、化肥、生长调节剂、饲料添加剂，以及基因工程生物及其产物。
                        </div>
                        <li class="ul-title">注意事项</li>
                        <div>
                            根据我国《有机产品认证管理办法》的规定，未获得有机产品认证的产品，不得在产品或者产品包装及标签上标注”有机产品”、”有机转换产品”（”ORGANIC”、”CONVERSION TO
                            ORGANIC”）等其他误导公众的文字表述。
                        </div>
                    </ul>
                </div>

                <div class="content" v-if="title=='环境管理体系认证'">
                    <div class="btit">
                        <div>环境管理体系认证服务[EMS]（ISO14001）</div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">什么是ISO14001环境体系</li>
                        <div>
                            1991年7月，国际标准化组织（ISO）成立了“环境战略咨询组”（SAGE），把环境管理标准化问题提上议事日程，经过一年多的工作，SAGE向ISO提出建议：要向质量管理一样，对环境也制定一套管理标准，以加强组织获得和衡量改善环境的能力。根据SAGE的建议，ISO于1993年6月正式成立一个专门机构TC207，着手制定环境管理领域的国际标准，即ISO14000环境管理系列标准。
                            地球变暖、臭氧层被破坏、冰川面积缩减、海洋污染、淡水危机、土地沙化、酸雨频频、热带雨林面积的锐减、动植物物种的逐步消亡……这无不向人们诉说着一个触目惊心的事实：人类的家园——地球，她的环境正在恶化，而我们赖以生存的地球只有一个。今天，人们已经认识到：人类对自然界的掠夺性破坏，终将遭到自然界的报复。
                            1972年6月5日，联合国在斯德哥尔摩召开了第一次环境大会，通过了《人类环境宣言》和《人类环境行动计划》，成立了联合国环境规划署，并把每年的6月5日定为“世界环境日”。这次会议不仅标志着全世界对环境问题的认识已达成共识，而且意味着实际行动的开始。此后，联合国环境机构召开了一系列会议，制定、签订了许多公约、协定。由于社会各界环境意识的提高和政府依法加强治理，许多企业主动改善环境绩效，到20世纪80年代，已经积累了不少环境管理的经验。
                            1996年，ISO首批颁布了与环境管理体系及其审核有关的5个标准，引起了各国政府和产业界的高度重视。到1997年底，标准颁布仅一年时间，全世界就有1491家企业通过ISO14001标准的认证；到1998年底，这一数字达到5017家；到1999年底，通过认证的企业已超过一万家。
                        </div>

                        <li class="ul-title">实施ISO14001的好处</li>
                        <div>

                            1.外部动机<br>
                            A 来自政府的压力：各国政府的环境立法和执法日趋严厉，企业一旦违法或造成环境事故将受到巨额罚款甚至会被迫关门。<br>
                            B 社区居民的压力：随著环保意识和法律观念的增强，社区居民环境污染的投诉和诉讼会渐渐增多，影响企业社会形象和公众形象。<br>
                            C 市场的压力，也是主要动力：市场压力首先来自国际市场的竞争。获取ISO14001 认证证书等於取得一张国际贸易的“绿色通行证”，打破绿色贸易壁垒。<br>
                            2. 内部效益<br>
                            A 增强环境意识，促进企业减少污染：建立环境管理体系，可摸清企业自身的环境状况，增强对保护环境的责任感，以减少污染。<br>
                            B 提高企业的管理水平：环境管理体系是一个系统的管理机制，作为一个有效的手段和方法，提高企业的环境管理水平。<br>
                            C
                            掌握环境状况、节能降耗、降低成本：标准要求对企业生产全过程进行有效控制，从最初的设计到最终的产品及服务都考虑了减少污染物的产生、排放和对环境的影响，能源、资源和原材料的节约、废物的回收利用等环境因素，并通过设定目标、指标、管理方案以及运行控制对重要的环境因素进行控制，可以有效地促进减少污染，节约资源和能源。<br>
                            D 企业通过ISO
                            14001标准，不但顺应国际和国内在环保方面越来越高的要求，不受国内外在环保方面的制约，优先享受国内外在环保方面的优惠政策和待遇，有效地促进企业环境与经济的协调和持续发展。<br>
                            3. 益处<br>
                            A 提高市场竞争力<br>
                            通过ISO14000认证的企业向顾客提供这样的信息：“一个能对环境负责的企业，它的产品和服务一定能对用户负责，让用户满意”；“企业的关注点，已不仅仅是质量，而是对人类的责任。”
                            实施ISO14000认证已成为代表企业形象的重要因素，很多获证企业在广告宣传中以此表示本企业对环境的贡献，从而扩大自己的市场份额。<br>
                            B 加强管理，降低成本<br>
                            实施ISO14000认证，除了要符合法律法规要求之外，污染预防，节约能源和资源是环境管理同样重要的两个方面。<br>
                            C 减少环境责任事故<br>
                            通过认证的大多数企业均已实现达标排放，因而免收了超标排污费，使企业直接从中获得了经济效益。<br>
                            D 提高企业环境管理水平<br>
                            建立环境管理体系的过程也是企业对全体员工进行教育的过程。这个过程会大大提高员工的运用P-D-C-A的管理模式，对企业进行有效管理。企业通过实施ISO14000变粗放型管理为集约型管理，使自己的管理水平得到明显提高，并全面优化各方面的管理，做到最小环境影响控制，最低物

                        </div>

                        <li class="ul-title">适用范围</li>
                        <div>
                            ISO14000系列标准的重要特点是，首先，该标准不是强制的，而是自愿采用的。ISO14000 系列标准借鉴了ISO9000
                            标准的成功经验，使标准具有广泛适用性和灵活性，它可适用于任何类型与规模，处于不同地理、文化和社会条件下的组织。ISO14000系列标准同ISO9000标准有很好的兼容性，使企业在采用ISO14000系列标准时，能与原有的管理体系有效协调。
                        </div>

                        <li class="ul-title">申请条件</li>
                        <div>
                            1）申请方必须具备有独立的法人资格，不具备独立法人资格的申请方，申请认证时要有法人的授权证明。<br>
                            2）申请方遵守中国签定的国际环境保护法规、中国环境保护法律和环境保护行政法规。<br>
                            3）在审核组进入现场审核 前申请方应已按ISO14001标准和国家环境法律、法规及其它要求建立文件化的环境管理体系，并实施运行至少3个月。<br>
                            4）申请方在进行现场审核前，应至少按照ISO14001的要求进行一次完整内审并进行了管理评审；
                        </div>

                        <li class="ul-title">申请材料</li>
                        <div>
                            a. 《环境管理体系认证申请书》；<br>
                            b. 法律地位的证明文件（如：企业法人营业执照、组织机构代码）复印件；<br>
                            c. 受控的环境管理体系手册和程序文件以及第三层次文件清单至少在审核前45天提供；<br>
                            d. 申请环境管理体系认证所覆盖的范围（包括产品范围、地域范围）；<br>
                            e. 申请组织概况；<br>
                            f. 申请组织机构分布平面图、工艺流程图、认证范围、下水管网图和社区平面图；<br>
                            g. 环境因素及重大环境因素清单；<br>
                            h. 适用于本组织的环境法律法规及其他要求清单等；
                        </div>

                        <li class="ul-title">必要时提供以下材料</li>
                        <div>
                            a. 环境影响报告书及环境主管部门批文复印件；<br>
                            b. “三同时”设施的设计方案及竣工验收报告和验收证明文件的复印件；<br>
                            c. 体系运行期间未受到环境行政处罚的证明；<br>
                            d. 上一年度以来的环境监测和污染源检验报告；<br>
                            e. 上一年度以来排污收费和环境管理奖惩记录；<br>
                            f. 上一年度以来相关方的投诉或抱怨记录；<br>
                            g. 当地环保主管部门对污染物浓度及总量控制指标的达标排放证明。

                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='有机产品'">
                    <div class="btit">
                        <div>有机产品认证</div>
                        <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #333333;margin: 10px 0">
                            认证范围：种植（作物、食用菌、野生采集）、养殖（畜禽养殖、蜜蜂养殖、水产养殖）、加工
                        </div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">什么是认证？什么是有机产品认证？</li>
                        <div>
                            《中华人民共和国认证认可条例》规定，认证是指由认证机构证明产品、服务、管理体系符合相关技术规范、相关技术规范的强制性要求或者标准的合格评定活动。有机产品认证是指认证机构按照《有机产品》国家标准和《有机产品认证管理办法》以及《有机产品认证实施规则》的规定对有机产品生产和加工过程进行评价的活动。在我国境内销售的有机产品均需经国家认监委批准的认证机构认证。
                        </div>

                        <li class="ul-title">有机农业就是传统农业吗？</li>
                        <div>
                            质量技术监督局受理企业的申请材料后，应在7个工作日内对符合申报条件的企业发放《生产许可证受理通知书》。
                        </div>

                        <li class="ul-title">什么是有机？</li>
                        <div>
                            这里的”有机”是指一种产品生产、加工方式，而非”有机化学”。根据我国《有机产品》国家标准（GB/T
                            19630-2011）的规定，有机产品是指生产、加工、销售过程符合该标准的供人类消费、动物食用的产品。我国《有机产品》国家标准规定，有机产品生产过程中不得使用化学合成的农药、化肥、生长调节剂、饲料添加剂，以及基因工程生物及其产物。
                        </div>

                        <li class="ul-title">注意事项</li>
                        <div>
                            根据我国《有机产品认证管理办法》的规定，未获得有机产品认证的产品，不得在产品或者产品包装及标签上标注”有机产品”、”有机转换产品”（”ORGANIC”、”CONVERSION TO
                            ORGANIC”）等其他误导公众的文字表述。
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='职业健康安全体系认证'">
                    <div class="btit">
                        <div>环境管理体系认证服务[EMS]（ISO14001）</div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">什么是 OHSAS18001职业健康安全管理体系</li>
                        <div>
                            OHSAS18000职业健康安全管理体系是由英国标准协会（BSI）、挪威船级社(DNV)
                            等13个组织于1999年联合推出的国际性标准，它是组织（企业）建立职业健康安全管理体系的基础，也是企业进行内审和认证机构实施认证审核的主要依据。它是继实施ISO9000、ISO14000国际标准之后的又一个热点。
                            OHSAS18000系列标准及由此产生的职业健康安全管理体系认证制度是近几年又一个风靡全球的管理体系标准的认证制度。OHSAS18000系列标准是由英国标准协会（BSI）、挪威船级社（DNV）等13个组织于1999年联合推出的国际性标准，在ISO尚未制定情况下，它起到了准国际标准的作用。其中的OHSAS18001标准是认证性标准，它是组织（企业）建立职业健康安全管理体系的基础，也是企业进行内审和认证机构实施认证审核的主要依据。中国已于2000年11月12日转化为国标：GB/T28001-2001
                            idtOHSAS18001:1999《职业健康安全管理体系规范》。同年12月20日，国家经贸委也推出了《职业安全健康管理体系审核规范》并在中国开展起职业健康安全管理体系认证制度。国标《职业健康安全管理体系要求》已于2011年12月30日更新至GB/T:280012011版本，等同采用OHSAS18001:2007新版标准（英文版）翻译，并于2012年2月1日实施。
                        </div>

                        <li class="ul-title">实施OHSAS18001的好处</li>
                        <div>
                            众所周知，在人们的工作活动或工作环境中，总是存在这样那样潜在的危险源，可能会损坏财物、危害环境、影响人体健康，甚至造成伤害事故。这些危险源有化学的、物理的、生物的、人体工效和其它种类的。人们将某一或某些危险引发事故的可能性和其可能造成的后果称之为风险。风险可用发生机率、危害范围、损失大小等指标来评定。现代职业安全卫生管理的对象就是职业安全卫生风险。
                            建立职业安全卫生管理体系对组织的意义虽然世界范围内只有几十家组织获得职业安全卫生管理体系认证，但建立职业安全卫生管理体系并寻求认证必将成为国际新潮流，其给组织带来的利益具体表现在以下几方面：<br>
                            A
                            通过第三方评审，取得职业安全卫生管理体系认证证书，并对外公告与有关标准的符合性，从而提高公司的组织形象；体现管理者对员工的关心，建立”以人为本”的组织文化，增强公司凝聚力；通过体系化和全员参与，完善内部管理；<br>
                            B
                            全面规范、改进企业职业健康安全管理、最大限度减少各种伤亡事故和职业疾病隐患，保障企业的财产安全，提高工作效率；最大程度地减少或消除组织经营的职业安全卫生风险，避免相关问题所造成的直接/间接损失，达到企业永续经营的目的；<br>
                            C提高企业形象，打破贸易壁垒，在国内外竞争中处于有利地位，进而提高市场份额；提供持续满足法律法规要求的机制，降低企业风险，预防事故发生,顺应国际贸易的新潮流。；<br>
                            D
                            改善政府、企业和员工的公共关系，增强企业凝聚力，提高企业综合竞争力；提高金融信贷信用等级，降低保险成本。承诺符合法律法规和其他要求，主动守法，善尽组织的国际/社会责任；从全球体系认证、产品认证的发展趋势看，职业安全卫生管理体系认证又将成为一种非关税贸易壁垒。及早建立体系并通过认证，可帮助组织抢占国际贸易先机。<br>
                        </div>

                        <li class="ul-title">适用范围</li>
                        <div>
                            为明确职业安全健康管理体系的基本要求，鼓励用人单位采用合理的职业安全健康管理原则与方法，控制其职业安全健康风险，持续改进职业安全健康绩效，特制定职业安全健康管理体系审核规范。<br>
                            此规范适用于任何有以下愿望的用人单位：<br>
                            （1）建立职业安全健康管理体系，有效地消除和尽可能降低员工和其他有关人员可能遭受的与用人单位活动有关的风险；<br>
                            （2）实施、维护并持续改进其职业安全健康管理体系；<br>
                            （3）保证遵循其声明的职业安全健康方针；<br>
                            （4）向社会表明其职业安全健康工作原则；<br>
                            （5）谋求外部机构对其职业安全健康管理体系进行认证和注册；<br>
                            （6）自我评价并声明符合本规范。<br>
                            规范中提出的所有要求，旨在帮助用人单位建立职业安全健康管理体系，其适用的程度取决于用人单位的职业安全健康方针、活动的特点及其风险的性质和运行的复杂性。
                        </div>

                        <li class="ul-title">申请条件</li>
                        <div>
                            （1） 申请方必须具备独立的法人资格，不具备独立法人资格的申请方，申请认证时要有法人的授权证明。<br>
                            （2）申请方遵守中国政府为保护劳工状况而签定的国际公约、中国职业健康安全法律法规和职业健康安全行政法规。<br>
                            （3）在审核组进入现场审核前申请方应已按照GB/T28001标准、国家职业健康安全法律法规及其它要求建立文件化的职业健康安全管理体系，并实施运行至少3个月。<br>
                            （4）. 申请方在进行现场审核前，其职业健康安全管理体系应已有效运行，至少进行了一次完整内审并进行了管理评审。
                        </div>

                        <li class="ul-title">必要时提供以下材料</li>
                        <div>
                            A. 《职业健康安全管理体系认证申请书》；<br>
                            B. 法律地位的证明文件（如：企业法人营业执照）复印件；<br>
                            C. 受控的职业健康安全管理体系手册和程序文件以及第三层次文件清单，这些文件及清单应在审核前至少20天提供；<br>
                            D. 申请职业健康安全管理体系认证所覆盖范围（包括产品范围和地域范围）；<br>
                            E. 申请组织概况、平面布置图、主要设备、工艺/业务流程图；<br>
                            F. 危险源及重大危险源清单；<br>
                            G. 适用于本组织的职业健康安全法律法规及其他要求的清单；<br>
                            H. 体系运行期间未受到职业健康安全行政处罚的证明；<br>
                            I. 相关方的投诉或抱怨及整改记录；<br>
                            J. 与申请认证组织相关的其他适用的背景资料，如危险化学品清单。
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='食品安全管理体系认证'">
                    <div class="btit">
                        <div>食品安全管理体系认证服务[FSMS]（ISO22000）</div>
                        <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #333333;margin: 10px 0">
                            近几年，从疯牛病到大肠杆菌的发作，从转基因食品到苏丹红染料的辩论，对食品安全的忧虑都成为轰动新闻。与这些忧虑相适应的是食品行业积极的试图找出改善食品安全管理的方法。
                            现有的食品安全验证的工具包括HACCP（危害分析和关键控制点）原理、英国零售协会（BRC）全球食品标准、国际食品标准（IFS）、安全质量食品（SQF）协议，以及欧洲零售商农产品工作集团的良好农业规范（EurepGAP）等。所有的工具都习惯性的帮助零售商管理他们的供应链，同时每一个标准在他们各自的领域提供相应的解决方案。
                            ISO
                            22000:2005标准《食品安全管理体系－对食物链中各类组织的要求》已于2005年9月1日由国际标准化组织（ISO）正式发布。ISO22000:2005食品安全管理体系既是一项食品安全管理体系的标准，又是一个可用于审核的标准，这为我们带来了一个在食品安全领域将国际上众多食品安全标准统一的机会，也成为在整个食品供应链中实施HACCP，提高食品安全的工具。
                            ISO
                            22000结合了相互沟通、体系管理、前提方案、HACCP原理等普遍认同的关键要素。整合了国际食品法典委员会（CAC）制定的危害分析及关键控制点HACCP）体系和实施步骤。该标准的颁布将取代目前各国存在的大多数食品安全管理标准，我国等同采用ISO
                            22000:2005的GB/T 22000-2006国家标准已于2006年7月1日发布实施。该标准也将使目前存在过多的食品安全管理体系认证统一到一个标准之下。
                        </div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">ISO 22000:2005标准的开发主要是要达到以下目标:
                        </li>
                        <div>
                            - 符合CAC的HACCP原理；<br>
                            - 提供一个用于审核（内审、第二方审核、第三方审核）的标准；<br>
                            - 构架与ISO9001:2000和ISO14001:2004相一致；<br>
                            - 提供一个关于HACCP概念的国际交流平台。<br><br>
                            因此，ISO
                            22000:2005不仅仅是通常意义上的食品加工规则和法规要求，而是寻求一个更为集中、一致和整合的食品安全体系，为构筑一个食品安全管理体系提供一个框架，并将其与其他管理活动相整合，如质量管理体系和环境管理体系等。
                            ISO
                            22000的使用范围覆盖了食品链全过程，即种植、养殖、初级加工、生产制造、分销，一直到消费者使用，其中也包括餐饮。另外，与食品生产密切相关的行业也可以采用这个标准建立食品安全管理体系，如杀虫剂、兽药、食品添加剂、储运、食品设备、食品清洁服务、食品包装材料等。
                            ISO
                            22000适合于所有食品加工企业的标准，它是通过对食品链中任何组织在生产（经营）过程中可能出现的危害（指产品）进行分析，确定控制措施，将危害降低到消费者可以接受的水平。因此，可以说ISO22000是从以HACCP原理为核心的食品安全控制体系发展到（食品安全管理体系）食品链中各类组织的要求。<br>
                        </div>

                        <li class="ul-title">ISO 22000和HACCP认证的关系</li>
                        <div>
                            中国认证认可监督管理委员会（CNCA）已于2007年7月18日发布的《关于食品安全管理体系认证审批政策的说明》中明确了二者的定义：依据GB/T22000（ISO22000）开展的认证定义为“食品安全管理体系认证”（简称FSMS认证）；依据HACCP及相关技术规范开展的认证为HACCP认证。由于HACCP认证已经在国内外贸易中得到普遍承认，因此在一段时间内GB/T22000（ISO22000）认证将与HACCP认证并存。通过GB/T
                            22000（ISO22000）认证的企业，如在HACCP业务受理范围内，可根据企业的要求发放HACCP证书。
                        </div>

                        <li class="ul-title">共同特点</li>
                        <div>
                            A. 是建立在组织良好的食品卫生管理的基础上的管理体系 <br>
                            B. 是基于科学分析建立的体系，需要一定的技术支持<br>
                            C. 是用于保护食品防止生物、化学和物理危害的管理工具，通过对所有潜在危害进行分析，确定预防措施，减少、消除、防止危害发生<br>
                            D. 与依靠化学分析、微生物检验进行控制相比较，具有较高的经济性<br>
                            E. 强调关键点的控制，在食品生产过程中，重点关注解决关键问题<br>
                            F. 是一个动态的体系，体系及控制措施随产品、生产条件等因素改变而变化<br>
                            G. 得到各国政府、联合国粮农组织和世界卫生组织下属的食品法典委员会（CAC）及国际标准化组织（ISO）的认同
                        </div>

                        <li class="ul-title">申请条件</li>
                        <div>
                            1）申请方必须具备有独立的法人资格，不具备独立法人资格的申请方，申请认证时要有法人的授权证明。<br>
                            2）申请方遵守中国签定的国际环境保护法规、中国环境保护法律和环境保护行政法规。<br>
                            3）在审核组进入现场审核 前申请方应已按ISO14001标准和国家环境法律、法规及其它要求建立文件化的环境管理体系，并实施运行至少3个月。<br>
                            4）申请方在进行现场审核前，应至少按照ISO14001的要求进行一次完整内审并进行了管理评审；
                        </div>

                        <li class="ul-title">实施意义</li>
                        <div>
                            A. 增强顾客消费信心，更有效地维护消费者的利益<br>
                            B. 改善组织内部营运，降低产品损耗<br>
                            C. 将管理集中于关键点上，预防更为有效<br>
                            D. 出现问题容易追溯，分清责任，减少纠纷<br>
                            E. 增强组织的品牌优势，提高组织市场竞争力<br>
                            F. 达到或超越市场及政府的要求
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='食品危害分析与关键控制点'">
                    <div class="btit">
                        <div>食品危害分析与关键控制点HACCP认证服务</div>
                        <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #333333;margin: 10px 0">
                            HACCP是一项国际认可的技术，用意是希望公司或生产商能通过此系统来降低，甚至防止各种类型的食品污染（包括微生物、化学性和物理性）。它也是一套分析在食物生产过程中可能涉及的危害，并加以控制来预防产生危害的体系。
                            HACCP主要通过识别风险，建立控制点，设定控制限度，执行前对控制措施进行验证，确认并加以监控等来达到目的。
                        </div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">为何要使用HACCP？</li>
                        <div>
                            近年来，食品的质量及安全性引起了广泛的注意。但食品供应链的安全却没有经过仔细的审查，消费者越来越了解与食品有关的问题，法律也越来越积极保护食品的安全，配合国际上和国内对质量保证的重视，消费者对以下三方面的要求十分严格：<br>
                            1、食品的安全性<br>
                            2、质量的稳定性<br>
                            3、物有所值
                        </div>

                        <li class="ul-title">HACCP的七项主要原则</li>
                        <div>
                            1、进行危害分析 <br>
                            2、确定关键控制点(CCP)<br>
                            3、确定关键控制限<br>
                            4、监控每一个关键控制点<br>
                            5、当关键控制点失控时，决定应采取的纠正措施<br>
                            6、建立验证程序确保HACCP体系能有效地运行<br>
                            7、建立有关程序及记录的文件体系以配合上述原则的应用
                        </div>

                        <li class="ul-title">中国的食品业离HACCP有多远</li>
                        <div>
                            近年来，全世界对食品安全的日益关注，以及经济的全球化推动了HACCP体系认证的不断发展，入世后，我国食品业拓展市场的最大障碍将不是关税，也不是知识产权，而是食品安全卫生。
                            目前，美国、欧盟已立法强制性要求食品生产企业建立和实施HACCP体系，日本、加拿大、澳大利亚等国家食品卫生当局也已开始要求本国食品企业建立和实施HACCP体系。我国也将食品安全问题列入《中国食品与营养发展纲要》，并强制要求罐头、水产品、肉及肉制品、速冻蔬菜、果蔬汁、含肉或水产品的速冻方便食品等六类食品出口企业必须建立HACCP食品安全体系。某些著名食品生产营销企业，也开始以HACCP作为考核供应商的重要条件。这就使得HACCP成为食品企业竞争国际市场的一张“通行证”。
                        </div>

                        <li class="ul-title">HACCP的效益</li>
                        <div>
                            1、维护企业声誉，增强品牌效应<br>
                            2、树立顾客对产品的信心<br>
                            3、符合法规及市场的需求<br>
                            4、减少责任的发生<br>
                            5、内部过程改善<br>
                            6、减少顾客审核的次数<br>
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='食品生产许可咨询认证'">
                    <div class="btit">
                        <div>生产许可咨询认证</div>
                        <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #333333;margin: 10px 0">
                            生产许可证是国家对于具备某种产品的生产条件并能保证产品质量的企业,依法授予的许可生产该项产品的凭证。
                            适用范围：生产许可证 (以下简称《申请书》)适用于企业发证、换证、迁址、增项等的生产许可证申请。
                        </div>
                        <div>主要介绍：</div>
                    </div>
                    <ul>
                        <li class="ul-title">办理</li>
                        <div>
                            企业办理生产许可证必须填写统一格式的《生产许可证申请表》一式四份，报质量技术监督局业务科。<br>
                            企业应同时提供如下资料:企业法人营业执照、例行(型式)检验报告、环保、卫生证明等。<br>
                            业务科将所有资料初审合格后，将申请资料报质量技术监督局质量科，由市局统一安排初审和检查。<br>
                            市局初审通过后将申请材料报省质量技术监督局。
                        </div>

                        <li class="ul-title">HACCP的七项主要原则</li>
                        <div>
                            1、进行危害分析 <br>
                            2、确定关键控制点(CCP)<br>
                            3、确定关键控制限<br>
                            4、监控每一个关键控制点<br>
                            5、当关键控制点失控时，决定应采取的纠正措施<br>
                            6、建立验证程序确保HACCP体系能有效地运行<br>
                            7、建立有关程序及记录的文件体系以配合上述原则的应用
                        </div>

                        <li class="ul-title">受理</li>
                        <div>
                            质量技术监督局受理企业的申请材料后，应在7个工作日内对符合申报条件的企业发放《生产许可证受理通知书》。
                        </div>

                        <li class="ul-title">现场审查</li>
                        <div>
                            1、生产许可证实施细则规定由省级质量技术监督局负责组织企业生产条件审查和封样的，省级质量技术监督局应在受理申请后2个月内组织对生产条件进行审查并现场抽封样品。省许可证办公室自受理企业申请之日起3个月内完成材料汇总，并将合格企业名单和相关材料报审查部。审查部自收到省级质量技术监督局报送材料之日起45日内完成企业生产条件抽查和材料汇总，并将合格企业名单和相关材料报送全国许可证办公室;<br>
                            2、生产许可证实施细则规定由审查部组织企业生产条件审查和封样的，省级质量技术监督局应在受理企业申请后15日内将相关材料转交审查部。审查部自接到省许可证办公室报送的材料之日起2个月内组织对申请取证企业的生产条件进行审查，并现场抽封样品。审查部自收到省许可证办公室报送的材料之日起3个月内将合格企业名单和相关材料报送全国生产许可证办公室;<br>
                            3、申请取证企业的生产条件审查工作由审查组承担，审查组实行组长责任制，审查组对审查报告负责。<br>
                        </div>

                        <li class="ul-title">样品检验</li>
                        <div>
                            申请取证企业应当在封样15日内将样品送达指定的检验机构。检验机构受到样品后，应当按照实施细则规定的标准和要求进行检验，并应当在规定的期限内完成检验工作。标准中对产品检验有特殊要求的，按标准规定进行。产品检验周期超过第十四条规定的材料报送时限时，材料报送时间以检验完成时间为准。
                        </div>

                        <li class="ul-title">程序</li>
                        <div>
                            全国许可证办公室自接到各省级质量技术监督局、审查部汇总的符合发证条件的企业名单和有关材料之日起1个月内完成审定。经审定，符合发证条件的，由国家质检总局颁发生产许可证，不符合发证条件的，将上报材料退回有关省级质量技术监督局或者审查部并告知企业。
                        </div>

                        <li class="ul-title">后处理</li>
                        <div>
                            省级质量技术监督局应当对不符合发证条件的企业发出《生产许可证审查不合格通知书》，同时收回《生产许可证受理通知书》。<br>
                            企业自接到《生产许可证审查不合格通知书》之日起，应当进行认真整改，2个月后方可再次提出取证申请。
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='标准制定服务'">
                    <div class="btit">
                        <div>标准制定服务</div>
                    </div>
                    <ul>
                        <li class="ul-title">服务范围</li>
                        <div>
                            团体标准、企业标准的制（修）订服务。
                        </div>

                        <li class="ul-title">服务内容</li>
                        <div>
                            服务内容
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='食品标签咨询'">
                    <div class="btit">
                        <div>预包装食品标签及食品营养标签咨询服务</div>
                        <div>预包装食品标签</div>
                    </div>
                    <ul>
                        <li class="ul-title">范围</li>
                        <div>
                            适用于直接提供给消费者的预包装食品标签和非直接提供给消费者的预包装食品标签。
                        </div>

                        <li class="ul-title">术语和定义</li>
                        <div>
                            A 预包装食品：预先定量包装或者制作在包装材料和容器中的食品，包括预先定量包装以及预先定量制作在包装材料和容器中并且在一定量限范围内具有统一的质量或体积标识的食品。 <br>
                            B 食品标签：食品包装上的文字、图形、符号及一切说明物。<br>
                            配料：在制造或加工食品时使用的，并存在（包括以改性的形式存在）于产品中的任何物质，包括食品添加剂<br>
                            C 生产日期（制造日期）：食品成为最终产品的日期，也包括包装或灌装日期，即将食品装入（灌入）包装物或容器中，形成最终销售单元的日期。<br>
                            D 保质期：预包装食品在标签指明的贮存条件下，保持品质的期限。在此期限内，产品完全适于销售，并保持标签中不必说明或已经说明的特有品质。<br>
                            E 规格：同一预包装内含有多件预包装食品时，对净含量和内含件数关系的表述。
                        </div>

                        <div class="btit">
                            <div></div>
                            <div>预包装食品营养标签</div>
                        </div>

                        <li class="ul-title">范围</li>
                        <div>
                            适用于预包装食品营养标签上营养信息的描述和说明。
                        </div>

                        <li class="ul-title">术语和定义</li>
                        <div>
                            A 营养标签：预包装食品标签上向消费者提供食品营养信息和特性的说明，包括营养成分表、营养声称和营养成分功能声称。营养标签是预包装食品标签的一部分。<br>
                            B 营养素：食物中具有特定生理作用，能维持机体生长、发育、活动、繁殖以及正常代谢所需的物质，包括蛋白质、脂肪、碳水化合物、矿物质及维生素等。<br>
                            C 营养成分：食品中的营养素和除营养素以外的具有营养和（或）生理功能的其他食物成分。各营养成分的定义可参照 GB/Z21922《食品营养成分基本术语》。<br>
                            D 核心营养素：营养标签中的核心营养素包括蛋白质、脂肪、碳水化合物和钠。<br>
                            E 营养成分表：标有食品营养成分名称、含量和占营养素参考值（NRV）百分比的规范性表格。<br>
                            F 营养素参考值（NRV）：专用于食品营养标签，用于比较食品营养成分含量的参考值。<br>
                            G 营养声称：对食品营养特性的描述和声明，如能量水平、蛋白质含量水平。营养声称包括含量声称和比较声称。
                        </div>

                    </ul>
                </div>

                <div class="content" v-if="title=='上岗培训服务'">
                    <div class="btit">
                        <div>检验检测人员上岗培训服务</div>
                    </div>
                    <ul>
                        <li class="ul-title">检测培训服务背景</li>
                        <div>
                            随着科学技术进步和现代化管理发展步伐的加快，产品质量在生产领域、流通领域越来越受到人们的重视。根据《中华人民共和国劳动法》劳动和社会保障部
                            《招用技术工种从业人员规定》(第六号部长令)文件精神，为满足企业检验技术人员的实验技术水平，检测实验人员考核已经纳入职业资格管理体系，从事检测实验分析人员必须持证上岗。
                        </div>

                        <li class="ul-title">培训目标</li>
                        <div>
                            1、提高检验人员的专业水平； <br>
                            2、学员能达到劳动和社会保障部规定的检验初、中、高等级的职业标准要求，并了解相应的法律法规；<br>
                            3、在参加培训之后协助顺利取得相应级别的职业资格证书，并能持证独立上岗操作。
                        </div>

                        <li class="ul-title">培训对象</li>
                        <div>
                            企、事业单位中从事各类食品检验、测试、实验、分析、科学研发的人员。
                        </div>

                        <li class="ul-title">培训领域</li>
                        <div>
                            食品质检测领域
                        </div>

                        <li class="ul-title">培训内容</li>
                        <div>
                            1、食品检验的感官检验技术；<br>
                            2、食品检验的化学基础；<br>
                            3、实际操作技能培训；<br>
                            4、食品检验概述；<br>
                            5、食品专业分析与检验技术（按工种分）；<br>
                            6、误差分析与数据处理；<br>
                            7、化学分析与检验技术；<br>
                            8、仪器分析与检验技术&食品检验的微生物检验技术。
                        </div>

                    </ul>
                </div>
            </div>
        </div>

        <div class="flotbox">
            <div class="zxdh">
                <div>
                    <img src="@/assets/common/cicon (1).png" alt="">
                    <span>咨询电话</span>
                </div>
                <div>
                    <img src="@/assets/common/cicon (2).png" alt="">
                    <span>023-47xxxxxxx</span>
                </div>
                <div>
                    <img src="@/assets/common/cicon (3).png" alt="">
                    <span>service@gukotest.com</span>
                </div>
            </div>

            <div class="rkinfo">
                <div :class="acIndex==0?'selectclass':''" @click="changeAc(0)">食品生产许可认证</div>
                <div :class="acIndex==1?'selectclass':''" @click="changeAc(1)">质量管理体系认证</div>
                <div :class="acIndex==2?'selectclass':''" @click="changeAc(2)">环境管理体系认证</div>
                <div :class="acIndex==3?'selectclass':''" @click="changeAc(3)">职业健康安全体系认证</div>
                <div :class="acIndex==4?'selectclass':''" @click="changeAc(4)">食品安全管理体系认证</div>
                <div :class="acIndex==5?'selectclass':''" @click="changeAc(5)">食品危害分析与关键…</div>
                <div :class="acIndex==6?'selectclass':''" @click="changeAc(6)">有机食品认证</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "attestation",
        data() {
            return {
                title: this.$route.query.title,
                acIndex: 0
            }
        },
        created() {
            let tit = this.title
            switch (tit) {
                case '食品生产许可咨询认证':
                    this.acIndex = 0
                    break
                case '质量管理体系认证':
                    this.acIndex = 1
                    break
                case '环境管理体系认证':
                    this.acIndex = 2
                    break
                case '职业健康安全体系认证':
                    this.acIndex = 3
                    break
                case '食品安全管理体系认证':
                    this.acIndex = 4
                    break
                case '食品危害分析与关键控制点':
                    this.acIndex = 5
                    break
                case '有机产品':
                    this.acIndex = 6
                    break
            }
        },
        methods: {
            changeAc(num) {
                this.acIndex = num
                let tit = this.title
                switch (num) {
                    case 0:
                        this.title = '食品生产许可咨询认证'
                        break
                    case 1:
                        this.title = '质量管理体系认证'
                        break
                    case 2:
                        this.title = '环境管理体系认证'
                        break
                    case 3:
                        this.title = '职业健康安全体系认证'
                        break
                    case 4:
                        this.title = '食品安全管理体系认证'
                        break
                    case 5:
                        this.title = '食品危害分析与关键控制点'
                        break
                    case 6:
                        this.title = '有机产品'
                        break
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .container {
        position: relative;
    }

    li {
        list-style: none
    }

    li:before {
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: #707070;
        vertical-align: middle;
        margin-right: 14px;
    }

    .bbbox {
        box-sizing: border-box;
        width: 100%;
        padding: 90px 0 100px;
    }

    .ltitle {
        margin-top: 100px;
        font-size: 36px;
        font-weight: 500;
        color: #333333;
    }

    .gxtime {
        margin: 36px 0;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
    }

    .content {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #606060;

        ul {
            margin-top: 10px;
        }

        .btit {
            font-family: PingFang SC;
            font-weight: 400;
            color: #606060;

            div:nth-child(1) {
                font-size: 30px;
                margin-bottom: 20px;
            }

            div:nth-child(2) {
                font-size: 20px;
            }
        }
    }

    .ul-title {
        margin-bottom: 4px;
        margin-top: 10px;
        position: relative;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #606060;
        transform: translateX(-24px);
    }

    .flotbox {
        position: absolute;
        top: 400px;
        right: 10px;
        width: 200px;
        .zxdh {
            width: 100%;
            background: #FBFBFB;
            div {
                width: 100%;
                height: 46px;
                padding-left: 18px;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #888888;
                img {
                    padding: 10px;
                    box-sizing: content-box;
                    margin-right: 11px;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: #D1D3D4;
                }
            }
            div:nth-child(1) {
                background: #172244;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                img {
                    background: #1320E8;
                }
            }
        }

        .rkinfo {
            margin-top: 30px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #888888;
            div {
                width: 100%;
                height: 46px;
                padding: 0 18px;
                display: flex;
                align-items: center;
                background: #FBFBFB;
                //border-bottom:1px solid #707070;
                position: relative;

                &:hover {
                    cursor: pointer;
                }
                &:last-child {
                    border: none;
                    &:after {
                        display: none
                    }
                }

                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: #707070;
                    transform: scaleY(.2);
                }

            }

            .selectclass {
                background: #3D4BD0;
                color: #FFFFFF;
            }
        }
    }
</style>